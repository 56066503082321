import { apiURL, app } from '../main'
import { showError } from './axios'

export default class DocumentService {
  static async load(tag: string) {
    try {
      const result = await app.axios.get(apiURL + '/documents/search/findByTagContains', {
        params: {
          size: 10000,
          tag: tag,
          sort: 'id,desc'
        }
      })
      if (await result) {
        return result.data
      }
    } catch (e) {
      showError(e)
    }
  }
}
