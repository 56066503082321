<template>
  <div id="app" @dragover.prevent @drop.prevent>
    <div
      class="container"
      @dragleave="fileDragOut"
      @dragover="fileDragIn"
      @drop="handleFileDrop"
      v-bind:style="{
        'background-color': this.color,
        'border-style': 'dashed',
        'border-width': '1px'
      }"
    >
      <br />
      <div class="file-wrapper">
        <v-icon size="40px">mdi-file-upload</v-icon>
        <div v-html="$t('drag_and_drop')"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'dragAndDrop',
  data() {
    return {
      files: [],
      color: '#e2e1e1'
    }
  },
  methods: {
    handleFileDrop(e) {
      // this approach could handle multi-upload of files, but atm we only emit 1 at the time
      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return
      ;[...droppedFiles].forEach((f) => {
        this.files.push(f)
      })
      this.$emit('submitDragAndDropFile', this.files[0])
      this.color = '#e2e1e1'
      this.files = []
    },
    fileDragIn() {
      this.color = '#90b6c5'
    },
    fileDragOut() {
      this.color = '#e2e1e1'
    }
  }
})
</script>
<style lang="scss" scoped>
.file-wrapper {
  text-align: center;
  height: 4.7em;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
}
</style>
