<template>
  <template v-if="show == true">
    <v-tabs v-model="activeTab" color="primary">
      <v-tab value="remarks" v-if="$privileges.has({ path: '/remarks', permission: 'read' }) && remarks_show">
        <v-badge :content="this.remarksBadge" :value="this.remarksBadge" v-if="this.remarksBadge > 0">
          <span v-html="$t('remarks')" />
        </v-badge>
        <span v-html="$t('remarks')" v-if="this.remarksBadge == 0" />
      </v-tab>
      <v-tab value="documents" v-if="$privileges.has({ path: '/documents/uploads', permission: 'read' }) && documents_show">
        <v-badge :content="this.documentsBadge" :value="this.documentsBadge" v-if="this.documentsBadge > 0"> <span v-html="$t('attachments')" /></v-badge>
        <span v-html="$t('attachments')" v-if="this.documentsBadge == 0" />
      </v-tab>
      <v-tab value="history" v-if="history_show"><span v-html="$t('history')" /></v-tab>
    </v-tabs>
    <v-card-text>
      <v-window v-model="activeTab">
        <v-window-item value="remarks" v-if="$privileges.has({ path: '/remarks', permission: 'read' }) && remarks_show">
          <Remarks :tag="remarks_tag" @counter="onRemarksGetCount" :remarks="remarks" @reloadRemarks="loadRemarks" />
        </v-window-item>
        <v-window-item value="documents" v-if="$privileges.has({ path: '/documents/uploads', permission: 'read' }) && documents_show">
          <Documents :tag="documents_tag" @reloadDocuments="loadDocuments" :documents="documentsData" />
        </v-window-item>
        <!-- history needs to have seperate privileges eg smpEncashments/history, it need to be configured outside this component -->
        <v-window-item value="history" v-if="history_show">
          <span v-html="$t('history')" /> <History :url="history_url" ref="historytabitem" :itemperpage="history_itemperpage" />
        </v-window-item>
      </v-window>
    </v-card-text>
  </template>
</template>

<script lang="ts">
import remarks from '@/components/rdh/remarks.vue'
import documents from '@/components/rdh/documents.vue'
import history from '@/components/rdh/history.vue'
import DocumentService from '@/services/documents'
import RemarksService from '@/services/remarks'

import { privileges } from '@/services/privileges'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'rdhComponent',
  props: {
    show: { type: Boolean, required: false, default: false },
    remarks_show: { type: Boolean, required: false, default: false },
    remarks_tag: { type: String, required: false },
    documents_show: { type: Boolean, required: false, default: false },
    documents_tag: { type: String, required: false },
    history_url: { type: String, required: false },
    history_show: { type: Boolean, required: false, default: false },
    history_itemperpage: { type: Number, required: false, default: 10 }
  },
  components: {
    Remarks: remarks,
    Documents: documents,
    History: history
  },
  data() {
    return {
      activeTab: 'remarks',
      remarksBadge: 0,
      documentsBadge: 0,
      remarks: [],
      documentsData: [],
      remarksService: RemarksService,
      documentsService: DocumentService
    }
  },

  methods: {
    onRemarksGetCount(value: number) {
      this.remarksBadge = value
    },
    onDocumentsGetCount(value: number) {
      this.documentsBadge = value
    },
    async loadRemarks() {
      let result = await this.remarksService.load(this.remarks_tag)
      this.remarksBadge = result.totalElements
      this.remarks = result.content
    },
    async loadDocuments() {
      this.documentsData = []
      this.documentsBadge = 0
      if (this.documents_show) {
        this.documentsData = await this.documentsService.load(this.documents_tag)
        this.documentsBadge = this.documentsData.length ? this.documentsData.length : 0
      }
    },
    reloadHistory() {
      this.$refs.historytabitem.loadHistory()
    }
  },

  watch: {
    async remarks_tag() {
      await this.loadRemarks()
    },
    async documents_tag() {
      await this.loadDocuments()
    }
  },
  mounted() {
    this.$nextTick(async () => {
      if (!this.show) return
      if (privileges.has({ path: '/remarks', permission: 'read' }) && this.remarks_show && this.remarks_tag) {
        await this.loadRemarks()
      }
      if (privileges.has({ path: '/documents/uploads', permission: 'read' }) && this.documents_show && this.documents_tag) {
        await this.loadDocuments()
      }
    })
  }
})
</script>
<style lang="scss" scoped>
.v-window-item .v-table {
  margin-top: 1rem;
}
</style>
