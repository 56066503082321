<template>
  <div class="remarksContainer">
    <v-table density="compact">
      <template v-slot:default>
        <thead>
          <tr>
            <th></th>
            <th class="text-left">
              <span v-html="$t('remarks_remark_text')" />
            </th>
            <th class="text-left">
              <span v-html="$t('remarks_remark_creation')" />
            </th>
            <th class="text-left">
              <span v-html="$t('remarks_remark_owner')" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(remark, i) in filteredRemarks" :key="remark.id">
            <td v-if="applicationUserId === remark.createdByApplicationUserId">
              <v-btn @click="remarkSwitchEnabled(remark, true)">
                <v-icon dbmblueprimary>mdi-text-box-edit-outline</v-icon>
              </v-btn>
            </td>
            <td v-else></td>
            <td v-if="remark.enabled">
              <v-text-field
                class="nomessage"
                :loading="remark.loading"
                density="compact"
                name="remark.text"
                v-model="remark.text"
                filled
                @blur="updateRemark(remark, remark.text)"
                @keyup.enter="updateRemark(remark, remark.text)"
                :id="'text_field_remark_' + remark.id"
                @keydown.enter.prevent="updateRemark(remark, remark.text)"
              ></v-text-field>
            </td>
            <td v-else @click="remarkSwitchEnabled(remark, applicationUserId === remark.createdByApplicationUserId)">
              {{ remark.text }}
            </td>
            <td>
              {{ format(remark.creation, 'dd.MM.yyyy HH:mm') }}
            </td>
            <td>{{ remark.createdByName }}{{ applicationUserId }}</td>
            <td v-if="applicationUserId == remark.createdByApplicationUserId || $privileges.has({ path: '/remarks', permission: 'deleteAnyUser' })">
              <v-btn :id="'remark' + i + '_delete'" @click="deleteRemark(remark)" icon :loading="remark.loading">
                <v-icon dbmblueprimary>mdi-delete</v-icon>
              </v-btn>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </template>
    </v-table>

    <div>
      <v-btn color="primary" @click="showCreateRemark"><span v-html="$t('remarks_button_new')" /></v-btn>
      <v-checkbox id="checkbox-1" v-model="showMore" :label="$t('remarks_show_more')" class="position-left"></v-checkbox>
    </div>
    <div v-if="showCreate" class="top-margin active">
      <v-text-field type="text" id="create_remark" class="input--block" v-model="model.text" autofocus />
      <br />
      <v-btn color="primary" @click="createRemark"><span v-html="$t('remarks_button_ok')" /></v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { app, apiURL, emitter } from '@/main'
import RemarksService from '../../services/remarks'
import { authService } from '@/services/auth.service'
import { format } from 'date-fns'

import { useLegalEntityStore } from '@/store/LegalEntityStore'

const defaultData = {
  id: 0,
  tag: '',
  text: ''
}

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'remarks',
  props: {
    tag: { type: String, required: true },
    remarks: { type: Array, required: true }
  },
  emits: ['reloadRemarks'],
  data() {
    return {
      format: format,
      apiURL: apiURL + '/remarks/search/findByTagContains',
      createURL: apiURL + '/remarks',
      deleteURL: apiURL + '/remarks',
      model: defaultData,
      app: app,
      showMore: false,
      showCreate: false,
      remarksService: RemarksService
    }
  },
  computed: {
    filteredRemarks() {
      let filteredRemarks = this.remarks
      if (filteredRemarks) {
        filteredRemarks = filteredRemarks.slice(0, 3)
      }
      return !this.showMore ? filteredRemarks : this.remarks
    },
    applicationUserId: {
      get() {
        let legalEntityStore = useLegalEntityStore()
        return legalEntityStore.getApplicationUser.id
      }
    }
  },
  methods: {
    async createRemark() {
      try {
        const result = await this.axios.post(`${apiURL}/remarks`, { ...this.model, ...{ tag: this.tag } }, { headers: { 'Content-Type': 'application/json' } })
        this.model.text = ''
        this.showCreate = false
        this.$emit('reloadRemarks')
      } catch (e) {
        showError(e)
      }
    },
    showCreateRemark() {
      this.showCreate = true
    },
    async deleteRemark(remark) {
      if (!confirm(this.$t('remarks_deletion_confirm'))) {
        return
      }
      remark.loading = true
      try {
        const result = await this.axios.delete(`${apiURL}/remarks/${remark.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })
        this.$emit('reloadRemarks')
      } catch (e) {
        showError(e)
      } finally {
        remark.loading = false
      }
    },
    async remarkSwitchEnabled(remark, bool) {
      if (!bool) {
        return
      }
      remark.enabled = !remark.enabled
    },
    async updateRemark(remark) {
      remark.loading = true
      try {
        const result = await this.axios.patch(`${apiURL}/remarks/${remark.id}`, { text: remark.text })
        emitter.emit(this.$t('toast_saved_message'))
      } catch (e) {
        showError(e)
      } finally {
        remark.loading = false
      }
    }
  }
})
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.remarksContainer {
  margin-bottom: 3rem;
}

.list-group {
  text-align: left;
}

.flex-items {
  display: flex;
  flex-flow: row;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.delete-remark:hover {
  cursor: pointer;
}

.position-left {
  float: right;
}

.position-right {
  float: right;
}

.margin-left {
  margin-left: $spacer-xs;
}

.top-margin {
  margin-top: $spacer-md;
}

.no-input {
  background: $dbm-white !important;
}
.v-window-item .v-table {
  margin-top: 1rem;
}
</style>
