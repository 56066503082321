<template>
  <div class="documentsContainer">
    <v-table density="compact">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              <span v-html="$t('documents_document_file_name')" />
            </th>
            <th class="text-left">
              <span v-html="$t('documents_document_owner_name')" />
            </th>
            <th class="text-left">
              <span v-html="$t('documents_document_creation')" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(document, i) in documents" :key="document.id">
            <td>
              <div v-if="document.deleted != null">
                <a dbmpassivgrey>
                  {{ document.fileName }}
                </a>
              </div>
              <div v-else>
                <a class="link" :href="protocol + document.uri">
                  {{ document.fileName }}
                </a>
              </div>
            </td>
            <td>{{ document.creatorName }}</td>
            <td>{{ format(document.creation, 'dd.MM.yyyy HH:mm') }}</td>
            <td v-if="applicationUserId == document.creatorApplicationUserId || $privileges.has({ path: '/documents', permission: 'delete' })">
              <v-btn v-if="document.deleted == null" :loading="document.isLoading" :id="'document' + i + '_delete'" @click="markDocumentDeleted(document)" icon>
                <v-icon dbmblueprimary>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                v-if="$privileges.has({ path: '/documents', permission: 'delete' }) && document.deleted != null"
                :loading="document.isLoading"
                :id="'document' + i + '_delete'"
                @click="deleteDocument(document)"
                icon
              >
                <v-icon dbmpassivgrey>mdi-delete</v-icon>
              </v-btn>
            </td>
            <td v-else></td>
          </tr>
        </tbody>
      </template>
    </v-table>
    <p></p>
    <v-form model="formStatus" @submit.prevent="onUploadFile()">
      <v-row>
        <v-col>
          <v-file-input v-model="selectedFile" truncate-length="15" :rules="[rules.required]" ref="inputdatafield"></v-file-input>
        </v-col>
        <v-col>
          <v-btn :disabled="isUploading" :loading="isUploading" color="primary" type="submit" class="upload-button"
            ><span v-html="$t('documents_upload')" /></v-btn
        ></v-col>
      </v-row>
    </v-form>
    <!-- <drag-and-drop @submitDragAndDropFile="setDragDropFile"></drag-and-drop>-->
  </div>
</template>

<script lang="ts">
import { showError } from '@/services/axios'
import { app, apiURL, protocol } from '@/main'
import DragAndDrop from './drag&drop'
import { authService } from '@/services/auth.service'
import { format } from 'date-fns'
import { useLegalEntityStore } from '@/store/LegalEntityStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'documents',
  props: {
    tag: { type: String, required: true },
    documents: { type: Array, required: true }
  },
  emits: ['reloadDocuments'],
  data() {
    return {
      formStatus: false,
      format: format,
      selectedFile: null,
      file: '',
      app: app,
      showMore: false,
      showCreate: false,
      isUploading: false,
      protocol: protocol,
      rules: {
        required: (value) => !!value || this.$t('form_field_required')
      }
    }
  },
  components: { DragAndDrop },
  computed: {
    applicationUserId: {
      get() {
        let legalEntityStore = useLegalEntityStore()
        return legalEntityStore.getApplicationUser.id
      }
    }
  },
  methods: {
    onDocumentClick(document) {
      window.open(document.uri)
    },
    async setDragDropFile(values) {
      if (values.size > 0) {
        this.selectedFile = values
      }
    },
    async onUploadFile() {
      if (!this.selectedFile?.name) {
        return false
      }
      try {
        const formData = new FormData()
        this.isUploading = true
        formData.append('file', this.selectedFile)
        // sending file to the backend
        let legalEntityStore = useLegalEntityStore()

        const postResult = await this.axios.post(`${apiURL}/documents`, {
          filename: this.tuneFileName(this.selectedFile.name),
          owner: { id: legalEntityStore.getSelectedPersonId },
          receiver: { id: legalEntityStore.getSelectedPersonId },
          title: this.tuneFileName(this.selectedFile.name),
          legalEntity: undefined,
          documentType: { code: '92', id: 92 },
          tag: this.tag
        })
        const id = postResult.data.id

        formData.append('file', this.selectedFile) // appending file byte array
        await this.axios.post(`${apiURL}/documents/${id}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        this.$emit('reloadDocuments')
      } catch (e) {
        showError(e)
      } finally {
        this.selectedFile = null
        this.isUploading = false
      }
    },
    showCreateDocument() {
      this.showCreate = true
    },
    tuneFileName(filename) {
      const maxlength = 95
      let fileEnding = filename.substring(filename.lastIndexOf('.') + 1, filename.length)
      let fileBeginning = filename.substring(0, filename.lastIndexOf('.'))
      fileBeginning = fileBeginning.replace(/[^a-zA-Z0-9]/g, '_')
      fileBeginning = fileBeginning.substring(0, maxlength - fileEnding.length - 1)
      return `${fileBeginning}.${fileEnding}`
    },
    async markDocumentDeleted(document) {
      if (!confirm(this.$t('documents_mark_for_deletion_confirm'))) {
        return
      }
      document.isLoading = true
      try {
        await this.axios.get(`${apiURL}/documents/${document.id}/setDeleted`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })
        this.$emit('reloadDocuments')
      } catch (e) {
        showError(e)
      } finally {
        document.isLoading = false
      }
    },
    async deleteDocument(document) {
      if (!confirm(this.$t('documents_deletion_confirm'))) {
        return
      }
      document.isLoading = true
      try {
        await this.axios.get(`${apiURL}/documents/${id}/upload/${deleteURLdocument.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + authService.loadedUser.access_token
          }
        })
        this.documents = this.documents.filter(function (element) {
          return element.id != document.id
        })
        this.$emit('reloadDocuments')
      } catch (e) {
        showError(e)
      } finally {
        document.isLoading = false
      }
    }
  },
  mounted() {}
})
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.documentsContainer {
  margin-bottom: 3rem;
}

.flex-items {
  display: flex;
  flex-flow: row;
}

.delete-document:hover {
  cursor: pointer;
}

.margin-left {
  margin-left: $spacer-xs;
}
.v-window-item .v-table {
  margin-top: 1rem;
}
</style>
