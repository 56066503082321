import axios from 'axios'
import { apiURL } from '../main'
import { showError } from './axios'

export default class RemarksService {
  static async load(tag: string) {
    try {
      const result = await axios.get(apiURL + '/remarks/search/findByTagContains', {
        params: {
          size: 10000,
          tag: tag,
          sort: 'id,desc'
        }
      })
      if (await result) {
        return result.data
      }
    } catch (e) {
      showError(e)
    }
  }
}
